import React, { Component } from 'react'
import { Link } from 'gatsby'
import Menu from './menu'
import { SvgHamburger, SvgClose } from './icons'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: false,
      isOpen: false,
    }
  }

  componentDidMount() {
    if (window.innerWidth < 768) this.setState({ isMobile: true })
  }

  render() {
    const { isMobile, isOpen } = this.state
    return (
      <div className="header">
        <div className="heroHeader">
          <div className="logo">
            <Link to="/" />
          </div>
          <Menu />
        </div>
      </div>
    )
  }
}

export default Header
