import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Header from './header'
import Footer from './Footer'
import './layout.css'

const Layout = ({ children }) => (
  <>
    <Helmet
      title={'Cherry'}
      meta={[
        {
          name: 'copyright',
          content: 'Cherry',
        },
        {
          name: 'description',
          content: 'All about Cherry.',
        },
        {
          name: 'keywords',
          content: 'cherry, wordsmith, content',
        },
      ]}
    >
      <html lang="en" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>

    <div>
      <div className="content">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
