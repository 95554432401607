import React from 'react'
// import Typist from 'react-typist'

import Layout from '../components/layout'
// import CherryCast from './CherryCast'
// import Stream from './Stream'

// const Logo = require('../images/logo.png')

const IndexPage = () => (
  <Layout>
    <div />
    {/* <div className="mainheader">
      <div className="mainheader-left">
        <div className="content-left">
          <h1 className="white-text header-left">
            <Typist avgTypingDelay={60} cursor={{ blink: false }}>
              <Typist.Delay ms={1500} />
              Copywriters.
              <Typist.Delay ms={750} />
              <br />
              {'\n'}Purveyors of cracking ideas.
            </Typist>
          </h1>

          <img src={Logo} className="logo" />
        </div>
      </div>

      <div className="mainheader-right">
        <div className="mainheader-right-1">
          <div className="content-right">
            <p className="white-text header-right">
              We’re communicators. Story tellers. Attention grabbers. Our
              superpower is words. But they’re only part of the picture. We
              tackle brand positioning, strategy direction, information
              architecture, tone of voice and messaging of all kinds. From
              scripts to editorial, ads to environments, digital to corporate
              comms, we give voice to brands.
            </p>
          </div>
        </div>
        <div className="mainheader-right-2" />
      </div>
    </div> */}

    {/* <Stream /> */}

    {/* <CherryCast /> */}
  </Layout>
)

export default IndexPage
