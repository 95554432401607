import React from 'react'
import './footer.css'

const Logo = require('../../images/logo.png')
const Quotes = require('../../images/quotes.png')

const Footer = () => (
  <div className="footer">
    {/* <img className="footer-quotes" src={Quotes} /> */}

    {/* <div className="social-media">
      <div className="social-media-inner">
        <div className="social-media-icon">
          <div className="social-media-icon-image">
            <img
              src={require('../../images/linkedin.svg')}
              style={{
                width: 40,
                height: 40,
              }}
            />
          </div>
        </div>

        <div className="social-media-icon">
          <div className="social-media-icon-image">
            <img
              src={require('../../images/instagram.svg')}
              style={{
                width: 40,
                height: 40,
              }}
            />
          </div>
        </div>

        <div className="social-media-icon">
          <div className="social-media-icon-image">
            <img
              src={require('../../images/twitter.svg')}
              style={{
                width: 40,
                height: 40,
              }}
            />
          </div>
        </div>
      </div>
    </div>

    <h3 className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
      <span>^</span>
      <br />
      Back to the top
    </h3> */}

    <div className="footerBottom" id="contact">
      {/* <div className="footerLogo">
        <img src={Logo} className="watermark" />
      </div> */}
      <div className="footerContact">
        <div className="footerContactWrapper">
          <img
            src={Logo}
            style={{ width: '80vw', maxWidth: 400, maxHeight: 400 * 0.56 }}
          />
          <FooterContact
            name="Ruth Gavin"
            phone="07939 575 783"
            phoneLink="+4407939575783"
            email="ruth@cherryonit.com"
          />
          <br />
          <br />

          <FooterContact
            name="Vivienne Hamilton"
            phone="07768 706 733"
            phoneLink="+4407768706733"
            email="viv@cherryonit.com"
          />
          <br />
          <br />
          <br />
        </div>
      </div>
      {/* <div className="footerAddress">
        <FooterAddress />
      </div> */}
    </div>
  </div>
)

const FooterContact = ({ name, phone, email }) => (
  <div style={{ display: 'inline-block', textAlign: 'center' }}>
    <h3
      style={{
        fontFamily: 'Coolvetica',
        color: '#ffdde2',
        paddingBottom: 5,
      }}
    >
      {name}
    </h3>
    <a
      style={{
        display: 'block',
        fontSize: 18,
        textDecoration: 'none',
        paddingBottom: 5,
        color: '#ffdde2',
      }}
      href={`tel:${email}`}
    >
      {phone}
    </a>
    <a
      style={{
        display: 'block',
        fontSize: 18,
        textDecoration: 'none',
        paddingBottom: 5,
        color: '#ffdde2',
      }}
      href={`mailto:${email}`}
    >
      {email}
    </a>
  </div>
)

const FooterAddress = () => (
  <div className="footerAddressWrapper2">
    <div className="footerAddressWrapper">
      <h3
        style={{
          fontFamily: 'Coolvetica',
          color: '#ffdde2',
          paddingBottom: 5,
        }}
      >
        Cherry lives
      </h3>
      <p
        style={{
          display: 'block',
          fontSize: 18,
          textDecoration: 'none',
          paddingBottom: 5,
          color: '#ffdde2',
        }}
      >
        Unit 2, 57 Dalston Lane
      </p>
      <p
        style={{
          display: 'block',
          fontSize: 18,
          textDecoration: 'none',
          paddingBottom: 5,
          color: '#ffdde2',
        }}
      >
        London E8 2NG
      </p>
    </div>
  </div>
)

export default Footer
