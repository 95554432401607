import React from 'react'
import { Link } from 'gatsby'

const Menu = ({ isMobile }) => (
  <div className="menuWrapper">
    <ul className="menu">
      <li>
        <Link to="#contact">Contact</Link>
      </li>
    </ul>
  </div>
)

export default Menu
